body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.bScore-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.bScore-container p {
  white-space: pre-line;
}

/* Hide scrollbar globally by default */
::-webkit-scrollbar {
  display: none;
}

/* Show scrollbar only for larger screens */
@media only screen and (min-width: 768px) {
  /* Apply the default scrollbar styling for larger screens */
  ::-webkit-scrollbar {
    display: block;
  }
}
